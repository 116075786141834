import __NS_DYNAMIC_REACT_CONTENT___0 from "./index.strings/content.default.js";
import { useContent, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { createElement } from 'react';
import { AppStoreBadge } from './app-store-badges.ccm.css';
import { AppleAppStore, GooglePlayStoreBadge } from './app-store-badges';
import { LinkHelper } from 'legos/typography';
import { useExternalLinkHandler } from 'packages/external-link-opener/react';
export const AppleAppStoreBadgeLink = ({
  appLink
}) => createElement(LinkHelper, useExternalLinkHandler(appLink), createElement(AppStoreBadge.div, {
  title: useContent("a5c45b821eb757f42837b4ec5d24ed2e", {}, [], NS_DYNAMIC_REACT_CONTENT_MANAGER)
}, createElement(AppleAppStore, null)));
export const GooglePlayStoreBadgeLink = ({
  appLink
}) => createElement(LinkHelper, useExternalLinkHandler(appLink), createElement(AppStoreBadge.div, {
  title: useContent("26228869af3ffa6881c62bdb0343179a", {}, [], NS_DYNAMIC_REACT_CONTENT_MANAGER)
}, createElement(GooglePlayStoreBadge, null)));