
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.AppStoreBadge_3b771d6b1bbd69b9{display:inline-block;width:142px;height:43px;margin:4px}`;
styleInject(_css)

/** AppStoreBadge Props */
export type AppStoreBadgeCCM = {
  /** AppStoreBadge Component Custom Properties */
  // No custom properties found

  /** AppStoreBadge Modifier Flags */
  // No modifiers classes found
};
/** Base AppStoreBadge component */
export const AppStoreBadge: ComponentCreator<AppStoreBadgeCCM> = createComponentCreator({
  "name": "AppStoreBadge",
  "base": "AppStoreBadge_3b771d6b1bbd69b9",
  "prop": {},
  "mod": {}
});

